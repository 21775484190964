<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          ref="refreshCard"
          class="cardActions"
          action-refresh
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block"> {{
                  baslangic
                      ? baslangic + ' ile ' + bitis + ' tarih aralığındaki kayıtlar'
                      : $store.state.auth.workDay + ' Tarihli Konaklama Listesi'
                }}</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
                  @click="listeYazdir()"
              >
                <b-spinner v-if="listeLoading" small/>
                <feather-icon
                    v-else
                    icon="PrinterIcon"
                    class="mr-50"
                />
                Listeyi Yazdır
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-50 border-0 float-right"
                  @click="refreshStop('refreshCard')"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                />
                Sıfırla
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-50 border-0 float-right"
                  @click="downloadExcel()"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <b-spinner v-if="excelLoading" small/>
                <feather-icon
                    v-else
                    icon="FileTextIcon"
                    class="mr-50"
                />
                Excel Çıktısını Al
              </b-button>
            </b-col>
          </div>
        </b-row>
        <b-row :class="width < 1200 ? 'mt-5':''">
          <b-col cols="12">
            <b-input-group class="float-right flat-date justify-content-end" :class="width < 1200 ? 'mt-2':''">
              <b-input-group-prepend>
                <flat-pickr
                    v-model="baslangic"
                    class="form-control"
                />
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr
                    v-model="bitis"
                    class="form-control mr-2"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <b-tabs
                v-if="!filtre"
                id="printMe"
            >
              <b-tab
                  active
                  title="Aktif Konuklar"
              >
                <hr class="m-0"/>
                <b-row>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            @change="perPageChange($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-table
                    hover
                    striped
                    empty-text=""
                    :items="salaryListItems"
                    :fields="fields"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="md"
                    show-empty
                    small
                    @filtered="onFiltered"
                    @row-clicked="pushRezervasyon"
                >
                  <template #cell(odaKodu)="data">
                    {{ data.item.odaKodu }}
                  </template>
                  <template #cell(rezervasyonNo)="data">
                    <a @click="rezGo(data.item.rezervasyonNo)">{{
                        data.item.rezervasyonNo
                      }}</a>
                  </template>
                  <template #cell(adi)="data">
                    {{ data.item.adi }} {{ data.item.soyAdi }}
                  </template>
                  <template #cell(tarife)="data">
                    {{ tarifeGet(data.item.tarife) }}
                  </template>
                  <template #cell(fiyat)="data">
                    {{ data.item.fiyat }} TL
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, -8) }}
                  </template>
                  <template #cell(cikisTarih)="data">
                    {{ data.item.cikisTarih }}
                  </template>
                  <template #cell(checkOut)="data">
                    <b-badge
                        pill
                        :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                    >
                      <strong>
                        {{
                          data.item.odemeDurum == 1
                              ? 'Tahsilat Tam / Aktif Konaklama'
                              : 'Tahsilat Bek / Aktif Konaklama'
                        }}
                      </strong>
                    </b-badge>
                  </template>
                </b-table>
                <hr>
                <b-row class="alignItemsCenter mt-1">
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ todayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col cols="6">
                      <b-pagination
                          v-model="currentPage"
                          :align="'rigth'"
                          :per-page="perPage"
                          :total-rows="todayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="currentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
              <b-tab title="Çıkış Yapan Konuklar">
                <hr class="m-0"/>
                <b-row>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="TperPage"
                            :options="pageOptionsT"
                            size="sm"
                            @change="perPageChangeT($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filterT"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                  <b-table
                      hover
                      striped
                      empty-text=""
                      :items="tSalaryListItems"
                      :fields="fields"
                      :per-page="TperPage"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                      @row-clicked="pushRezervasyon"
                  >
                    <template #cell(odaKodu)="data">
                      {{ data.item.odaKodu }}
                    </template>
                    <template #cell(rezervasyonNo)="data">
                      <a @click="rezGo(data.item.rezervasyonNo)">{{
                          data.item.rezervasyonNo
                        }}</a>
                    </template>
                    <template #cell(tarife)="data">
                      {{ tarifeGet(data.item.tarife) }}
                    </template>
                    <template #cell(adi)="data">
                      {{ data.item.adi }} {{ data.item.soyAdi }}
                    </template>
                    <template #cell(kayitTarih)="data">
                      {{ data.item.kayitTarih.slice(0, -8) }}
                    </template>
                    <template #cell(cikisTarih)="data">
                      {{ data.item.cikisTarih }}
                    </template>
                    <template #cell(checkOut)="data">
                      <b-badge
                          pill
                          :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                      >
                        <strong>
                          {{
                            data.item.odemeDurum == 1
                                ? 'Tahsilat Tam / Çıkış Yapıldı'
                                : 'Tahsilat Bek / Çıkış Yapıldı'
                          }}
                        </strong>
                      </b-badge>
                    </template>
                  </b-table>
                  <hr>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ TtodayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col
                        cols="6"
                        class=""
                    >
                      <b-pagination
                          v-model="TcurrentPage"
                          :align="'rigth'"
                          :per-page="TperPage"
                          :total-rows="TtodayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="TcurrentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
            </b-tabs>
            <b-tabs
                v-if="filtre"
                id="printMe"
            >
              <b-tab
                  active
                  title="Aktif Konuklar"
              >
                <b-row>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            @change="perPageChange($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-table
                    hover
                    striped
                    empty-text=""
                    :items="salaryListItemsFiltreActive"
                    :fields="fields"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="md"
                    show-empty
                    small
                    @filtered="onFiltered"
                    @row-clicked="pushRezervasyon"
                >
                  <template #cell(odaKodu)="data">
                    {{ data.item.odaKodu }}
                  </template>
                  <template #cell(rezervasyonNo)="data">
                    <a @click="rezGo(data.item.rezervasyonNo)">{{
                        data.item.rezervasyonNo
                      }}</a>
                  </template>
                  <template #cell(adi)="data">
                    {{ data.item.adi }} {{ data.item.soyAdi }}
                  </template>
                  <template #cell(tarife)="data">
                    {{ tarifeGet(data.item.tarife) }}
                  </template>
                  <template #cell(fiyat)="data">
                    {{ data.item.fiyat }} TL
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, -8) }}
                  </template>
                  <template #cell(cikisTarih)="data">
                    {{ data.item.cikisTarih }}
                  </template>
                  <template #cell(checkOut)="data">
                    <b-badge
                        pill
                        :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                    >
                      <strong>
                        {{
                          data.item.odemeDurum == 1
                              ? 'Tahsilat Tam / Aktif Konaklama'
                              : 'Tahsilat Bek / Aktif Konaklama'
                        }}
                      </strong>
                    </b-badge>
                  </template>
                </b-table>
                <hr>
                <b-row class="alignItemsCenter mt-1">
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ todayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col cols="6">
                      <b-pagination
                          v-model="currentPage"
                          :align="'rigth'"
                          :per-page="perPage"
                          :total-rows="todayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="currentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
              <b-tab title="Çıkış Yapan Konuklar">
                <b-row>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="TperPage"
                            :options="pageOptionsT"
                            size="sm"
                            @change="perPageChangeT($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filterT"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                  <b-table
                      hover
                      striped
                      empty-text=""
                      :items="salaryListItemsFiltrePasif"
                      :fields="fields"
                      :per-page="TperPage"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                      @row-clicked="pushRezervasyon"
                  >
                    <template #cell(odaKodu)="data">
                      {{ data.item.odaKodu }}
                    </template>
                    <template #cell(rezervasyonNo)="data">
                      <a @click="rezGo(data.item.rezervasyonNo)">{{
                          data.item.rezervasyonNo
                        }}</a>
                    </template>
                    <template #cell(tarife)="data">
                      {{ tarifeGet(data.item.tarife) }}
                    </template>
                    <template #cell(adi)="data">
                      {{ data.item.adi }} {{ data.item.soyAdi }}
                    </template>
                    <template #cell(kayitTarih)="data">
                      {{ data.item.kayitTarih.slice(0, -8) }}
                    </template>
                    <template #cell(cikisTarih)="data">
                      {{ data.item.cikisTarih }}
                    </template>
                    <template #cell(checkOut)="data">
                      <b-badge
                          pill
                          :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                      >
                        <strong>
                          {{
                            data.item.odemeDurum == 1
                                ? 'Tahsilat Tam / Çıkış Yapıldı'
                                : 'Tahsilat Bek / Çıkış Yapıldı'
                          }}
                        </strong>
                      </b-badge>
                    </template>
                  </b-table>
                  <hr>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ TtodayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col cols="6">
                      <b-pagination
                          v-model="TcurrentPage"
                          :align="'rigth'"
                          :per-page="TperPage"
                          :total-rows="TtodayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="TcurrentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <vue-html2pdf
        :show-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="konaklama-rapor"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="konaklama-rapor"
    >
      <section slot="pdf-content" class=" pl-2 pr-2 font-size-12" ref="konaklama-rapor">
        <h3 class="text-center mt-2">Konaklama Listesi</h3>
        <p class="text-center mt-50">
          {{
            baslangic
                ? '(' + baslangic + ' ile ' + bitis + ')'
                : '(' + $store.state.auth.workDay + ' )'
          }}
        </p>
        <table class="table table-striped mt-2" id="excelTable">
          <thead>
          <tr class="m-0">
            <th class="m-0 p-0" v-for="(header, index) in headers" :key="index">{{ header }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, rowIndex) in rowsWithSpacers" :key="rowIndex"
              :style="{ height: row.spacer ? '100px':'10px',backgroundColor: row.spacer ? 'white':''}"
          >
            <td class="mb-0 p-0" v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell != true ? cell : '' }}</td>
          </tr>
          </tbody>
        </table>
      </section>
    </vue-html2pdf>
  </b-container>
</template>
<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BTabs,
  BTab,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'
import VueHtml2pdf from 'vue-html2pdf'
import store from '@/store'
import XLSX from '@/assets/xlsx/xlsx.full.min'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    VueHtml2pdf,
    BCardActions,
    BInputGroupPrepend,
    BRow,
    BCol,
    BContainer,
    BTable,
    BTabs,
    BTab,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      listeLoading: false,
      headers: [],
      rows: [],
      excelLoading: false,
      spinner: true,
      todayToplamKayit: '',
      TtodayToplamKayit: '',
      filtre: false,
      islem: false,
      baslangic: '',
      bitis: '',
      fields: [
        {
          key: 'odaKodu',
          label: 'ODA',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          }
        },
        {
          key: 'rezervasyonNo',
          label: 'RNO',
          class: 'text-center',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'tarife',
          label: 'TARİFE',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'fiyat',
          label: 'GECELİK FİYAT',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'checkOut',
          label: 'DURUM',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          }
        },
      ],
      totalRows: 1,
      TtotalRows: 1,
      currentPage: 1,
      TcurrentPage: 1,
      perPage: 20,
      TperPage: 20,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      pageOptionsT: [5, 10, 15, 20, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      filterT: null,
      filterOnT: [],
    }
  },
  computed: {
    rowsWithSpacers() {
      let result = []
      let spacerIndex = 45
      let increment = 45

      this.rows.forEach((row, index) => {
        result.push(row)

        if (index + 1 === spacerIndex) {
          result.push({ spacer: true })
          increment += 3

          // if (spacerIndex > 144) {
          //   console.log('144', row)
          //   increment += 3
          // } else if (spacerIndex < 192) {
          //   console.log('192', row)
          //   increment += 2
          // } else if (spacerIndex > 191) {
          //   console.log('191', row)
          //   increment -= 2
          // } else {
          //   console.log('son else', row)
          //   increment -= 0
          // }

          spacerIndex += increment
          console.log('spacerIndex', spacerIndex)
        }
      })
      return result
    },
    salaryListItems() {
      if (this.$store.getters.konaklamaListActive) {
        return Object.values(this.$store.getters.konaklamaListActive)
      }
    },
    tSalaryListItems() {
      if (this.$store.getters.konaklamaListPasif) {
        return Object.values(this.$store.getters.konaklamaListPasif)
      }
    },
    salaryListItemsFiltreActive() {
      if (this.$store.getters.konaklamaListActiveFiltre) {
        return Object.values(this.$store.getters.konaklamaListActiveFiltre)
      }
    },
    salaryListItemsFiltrePasif() {
      if (this.$store.getters.konaklamaListPasifFiltre) {
        return Object.values(this.$store.getters.konaklamaListPasifFiltre)
      }
    },
  },
  watch: {
    baslangic(newVal) {
      if (newVal) {
        this.currentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              start: newVal,
              end: this.bitis || mevcutGun,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
        const veriT = newVal * this.TperPage - this.TperPage
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              start: newVal,
              end: this.bitis || mevcutGun,
              uzunluk: this.TperPage,
              baslangic: veriT,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.TtodayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
            })
        if (!this.bitis) {
          this.bitis = mevcutGun
        }
      }
    },
    bitis(newVal) {
      if (newVal) {
        this.TcurrentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              start: this.baslangic || mevcutGun,
              end: newVal,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
        const veriT = newVal * this.TperPage - this.TperPage
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              start: this.baslangic || mevcutGun,
              end: newVal,
              uzunluk: this.TperPage,
              baslangic: veriT,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.TtodayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
            })
        if (!this.baslangic) {
          this.baslangic = mevcutGun
        }
      }
    },
    filter(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      } else {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    filterT(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      } else {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
  },
  created() {
    this.islem = true
    this.$store
        .dispatch('konaklamaListActive', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          this.islem = false
          this.todayToplamKayit = res.aktifSayac
          this.totalRows = res.aktifKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
    this.$store
        .dispatch('konaklamaListPasif', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.islem = false
          this.TtodayToplamKayit = res.pasifSayac
          this.TtotalRows = res.gelecekKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearPosFatura')
  },
  methods: {
    download(ref) {
      this.$refs[ref].generatePdf()
    },
    base64ToBlob(base64, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(base64)
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const len = binaryString.length
      const bytes = new Uint8Array(len)
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    },
    listeYazdir() {
      this.listeLoading = true
      const self = this
      const data = {
        crm_token: localStorage.getItem('otelUserToken'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesi',
        [this.baslangic ? 'start' : '']: this.baslangic,
        [this.baslangic ? 'end' : '']: this.bitis,
        [this.baslangic ? 'filtre' : '']: this.baslangic ? true : false,
      }
      $.ajax({
        type: 'POST',
        url: this.$store.state.POST_URL,
        data,
        success(res) {
          if (res.data) {
            const workbook = XLSX.read(self.base64ToArrayBuffer(res.data), { type: 'array' })
            const firstSheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[firstSheetName]
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            self.headers = json[0]
            self.rows = json.slice(1)
            self.download('konaklama-rapor')
            self.listeLoading = false
          } else {
            self.listeLoading = false
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: 'Konuk Bulunamadı',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        },
        error(err) {
          self.listeLoading = false
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: 'Hata Oluştu',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        },
        dataType: 'json',
      })
    },
    downloadExcel() {
      const self = this
      this.excelLoading = true
      const data = {
        crm_token: localStorage.getItem('otelUserToken'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesi',
        [this.baslangic ? 'start' : '']: this.baslangic,
        [this.baslangic ? 'end' : '']: this.bitis,
        [this.baslangic ? 'filtre' : '']: this.baslangic ? true : false,
      }
      $.ajax({
        type: 'POST',
        url: this.$store.state.POST_URL,
        data,
        success(res) {
          self.excelLoading = false
          if (res.data) {
            const blob = new Blob([self.base64ToBlob(res.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = self.baslangic && self.bitis ? `${self.baslangic}-${self.bitis} Konaklama L.` : 'Konaklama Listesi'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: 'Konuk Bulunamadı',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        },
        error(err) {
          self.excelLoading = false
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: 'Hata Oluştu',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        },
        dataType: 'json',
      })
    },
    pushRezervasyon(item) {
      this.$router.push({
        name: 'rezervasyon',
        params: { id: item.rezervasyonNo },
      })
    },
    currentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.perPage - this.perPage
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      } else {
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    TcurrentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.TperPage - this.TperPage
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filterT,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      } else {
        const veri = newVal * this.TperPage - this.TperPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: this.TperPage,
              baslangic: veri,
              searchKey: this.filterT,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
    perPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    perPageChangeT(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.todayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = this.TcurrentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filterT,
            })
            .then(res => {
              this.TcurrentPage = 1
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
    refreshStop() {
      this.filtre = false
      this.perPage = 20
      this.TperPage = 20
      this.baslangic = ''
      this.bitis = ''
      this.filter = ''
      this.filterT = ''
      this.islem = true
      this.$store
          .dispatch('konaklamaListActive', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.islem = false
            this.todayToplamKayit = res.aktifSayac
            this.totalRows = res.aktifKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
      this.$store
          .dispatch('konaklamaListPasif', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.islem = false
            this.TtodayToplamKayit = res.pasifSayac
            this.TtotalRows = res.gelecekKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    tarifeGet(tarifeID) {
      const veri = Object.values(this.$store.getters.tarife)
          .find(obj => obj.tarifeID == tarifeID)
      if (veri) {
        return veri.tarifeAdi
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.spacer td {
  height: 100px;
}

.spacer {
  margin: 60px;
}

.custom-select-sm {
  max-width: 80px !important;
}

.perPageText {
  transform: translate(-69px, 0px);
  font-size: 15px;
}

.cardActions .card-body {
  min-height: 550px;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}


.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}


fieldset legend {
  transform: translate(29px, -2px) !important;
}

.araButton {
  border-color: #d8d6de !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
}

.disabledColor {
  border-color: #cbc1eb !important;
}

.card-headerr .col-8 {
  padding-right: 0 !important;
}

hr {
  border-top: 1px solid #e5e2ef;
}

.alignItemsCenter {
  align-items: center !important;
}

.searchGroup {
  width: 60% !important;
}

.myTitle {
  white-space: nowrap !important;
  font-size: 1.2vw !important;
}

.flat-date {
  width: 100% !important;
}
</style>